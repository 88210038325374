var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-9 px-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_vm.dataLoaded ? _c('BaseCard', {
    staticClass: "pa-3",
    attrs: {
      "mainPageCard": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Påmelding til: " + _vm._s(_vm.course.externalTitle) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "px-6",
          attrs: {
            "color": "primary",
            "block": "",
            "data-cy": "submitApplication",
            "disabled": _vm.alreadyApplied || _vm.alreadyExpired
          },
          on: {
            "click": _vm.submitApplication
          }
        }, [_vm._v(" Send inn søknad ")])];
      },
      proxy: true
    }], null, false, 1003507234)
  }, [_c('BaseRow', [_c('BaseInfoCol', {
    attrs: {
      "label": "Tidsrom:",
      "data-cy": "timePeriod"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.course.startDate)) + " - " + _vm._s(_vm.formatDate(_vm.course.endDate)) + " ")]), _c('BaseInfoCol', {
    attrs: {
      "label": _vm.applicationFormTemplate.isVocationalSchool ? 'Studie-ID:' : 'Kurs-ID:',
      "data-cy": "courseId"
    }
  }, [_vm._v(_vm._s(_vm.$route.params.courseId))]), _vm.course.enrollmentDeadline ? _c('BaseInfoCol', {
    attrs: {
      "label": "Påmeldingsfrist:",
      "data-cy": "enrollmentDeadline"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.course.enrollmentDeadline)) + " ")]) : _vm._e(), _vm.course.economy ? _c('BaseInfoCol', {
    attrs: {
      "label": _vm.applicationFormTemplate.isVocationalSchool ? 'Studieavgift:' : 'Kursavgift:',
      "data-cy": "economy"
    }
  }, [_vm._v(_vm._s(_vm.course.economy.price) + " kr")]) : _vm._e(), _vm.courseArea ? _c('BaseInfoCol', {
    attrs: {
      "label": "Sted:",
      "data-cy": "courseArea"
    }
  }, [_vm._v(" " + _vm._s(_vm.courseArea.place) + " ")]) : _vm._e()], 1), _vm.alreadyApplied || _vm.alreadyExpired ? [_c('h2', {
    staticClass: "mx-3 red--text"
  }, [_vm._v(" " + _vm._s(_vm.alreadyApplied ? "Du har allerede søkt/meldt deg på" : "Påmeldingsfristen er utløpt") + " ")])] : [_c('BaseCard', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Personlig informasjon ")];
      },
      proxy: true
    }], null, false, 3574207010)
  }, [_c('ApplyCourseUserInfo', {
    attrs: {
      "activeUserInfo": _vm.activeUserInfo
    },
    model: {
      value: _vm.applicationModel,
      callback: function ($$v) {
        _vm.applicationModel = $$v;
      },
      expression: "applicationModel"
    }
  })], 1), _vm.applicationFormTemplate.isCitizenship || _vm.applicationFormTemplate.isPersonalNumber || _vm.applicationFormTemplate.isNorwegianMotherTongue ? _c('BaseCard', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Personalia ")];
      },
      proxy: true
    }], null, false, 1657993773)
  }, [_c('ApplyCoursePersonalInfo', {
    attrs: {
      "isCitizenship": _vm.applicationFormTemplate.isCitizenship,
      "isPersonalNumber": _vm.applicationFormTemplate.isPersonalNumber,
      "isNorwegianMotherTongue": _vm.applicationFormTemplate.isNorwegianMotherTongue,
      "isNorwegianCertificationLevel": _vm.applicationFormTemplate.isNorwegianCertificationLevel,
      "isNorwegianSkills": _vm.applicationFormTemplate.isNorwegianSkills
    },
    model: {
      value: _vm.applicationModel,
      callback: function ($$v) {
        _vm.applicationModel = $$v;
      },
      expression: "applicationModel"
    }
  })], 1) : _vm._e(), _vm.applicationFormTemplate.isEmployer ? _c('BaseCard', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Arbeidsgiver ")];
      },
      proxy: true
    }], null, false, 3043305624)
  }, [_c('ApplyCourseEmployerInfo', {
    attrs: {
      "isEmployerEmail": _vm.applicationFormTemplate.isEmployerEmail
    },
    model: {
      value: _vm.applicationModel,
      callback: function ($$v) {
        _vm.applicationModel = $$v;
      },
      expression: "applicationModel"
    }
  })], 1) : _vm._e(), _vm.applicationFormTemplate.isCostBearer ? _c('BaseCard', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Betalingsinformasjon ")];
      },
      proxy: true
    }], null, false, 1250023640)
  }, [_c('ApplyCourseCostBearer', {
    attrs: {
      "activeUserInfo": _vm.activeUserInfo,
      "isInvoiceReference": _vm.applicationFormTemplate.isInvoiceReference,
      "isInstallments": _vm.applicationFormTemplate.isInstallments,
      "isMonthly": _vm.applicationFormTemplate.isMonthly,
      "isSemiAnnually": _vm.applicationFormTemplate.isSemiAnnually,
      "isQuarterly": _vm.applicationFormTemplate.isQuarterly
    },
    model: {
      value: _vm.applicationModel,
      callback: function ($$v) {
        _vm.applicationModel = $$v;
      },
      expression: "applicationModel"
    }
  })], 1) : _vm._e(), _vm.applicationFormTemplate.isNewsletter ? _c('BaseCard', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Nyhetsbrev ")];
      },
      proxy: true
    }], null, false, 2855533859)
  }, [_c('BaseInfoCol', {
    attrs: {
      "label": "Ønsker nyhetsbrev? *"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "rules": [_vm.validateBoolean]
    },
    model: {
      value: _vm.applicationModel.isNewsletter,
      callback: function ($$v) {
        _vm.$set(_vm.applicationModel, "isNewsletter", $$v);
      },
      expression: "applicationModel.isNewsletter"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Ja",
      "value": true,
      "data-cy": "newsletterTrue"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Nei",
      "value": false,
      "data-cy": "newsletterFalse"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.applicationFormTemplate.isMemberOrganization ? _c('BaseCard', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Medlemsorganisasjon ")];
      },
      proxy: true
    }], null, false, 2661917360)
  }, [_c('ApplyCourseUnion', {
    attrs: {
      "isMemberOrganization": _vm.applicationFormTemplate.isMemberOrganization,
      "isRegionalOffices": _vm.applicationFormTemplate.isRegionalOffices,
      "activeFeatureSettings": _vm.activeFeatureSettings
    },
    model: {
      value: _vm.applicationModel,
      callback: function ($$v) {
        _vm.applicationModel = $$v;
      },
      expression: "applicationModel"
    }
  })], 1) : _vm._e(), _vm.applicationFormTemplate.isCompetence ? _c('BaseCard', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Kompetanse ")];
      },
      proxy: true
    }], null, false, 3305734284)
  }, [_c('ApplyCourseCompetency', {
    model: {
      value: _vm.applicationModel,
      callback: function ($$v) {
        _vm.applicationModel = $$v;
      },
      expression: "applicationModel"
    }
  })], 1) : _vm._e(), _vm.applicationFormTemplate.additionalQuestions && _vm.applicationFormTemplate.additionalQuestions.length > 0 ? _c('BaseCard', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Tilleggsspørsmål ")];
      },
      proxy: true
    }], null, false, 1966253576)
  }, [_c('ApplyCourseAdditionalQuestions', {
    attrs: {
      "questions": _vm.applicationFormTemplate.additionalQuestions
    },
    model: {
      value: _vm.applicationModel,
      callback: function ($$v) {
        _vm.applicationModel = $$v;
      },
      expression: "applicationModel"
    }
  })], 1) : _vm._e(), _vm.applicationFormTemplate.isCompetence ? _c('BaseCard', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Filer ")];
      },
      proxy: true
    }], null, false, 1301567753)
  }, [_c('ApplyCourseFiles', {
    attrs: {
      "courseFiles": _vm.courseFiles
    },
    on: {
      "newFile": _vm.addCourseTempFile,
      "removeFile": _vm.removeCourseTempFile
    }
  })], 1) : _vm._e(), _vm.applicationFormTemplate.regulationFileId ? _c('BaseCard', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Kursbestemmelser ")];
      },
      proxy: true
    }], null, false, 169131082)
  }, [_c('ApplyCourseRegulations', {
    attrs: {
      "regulationFileId": _vm.applicationFormTemplate.regulationFileId
    },
    model: {
      value: _vm.applicationModel,
      callback: function ($$v) {
        _vm.applicationModel = $$v;
      },
      expression: "applicationModel"
    }
  })], 1) : _vm._e()]], 2) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }