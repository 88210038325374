var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseRow', [_c('BaseInfoCol', {
    attrs: {
      "label": "Medlemsorganisasjon: *"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "data-cy": "appForm-main-office-select",
      "items": _vm.activeMainOffices,
      "rules": [_vm.validateNotEmpty],
      "label": "Medlemsorganisasjon",
      "item-text": "name",
      "item-value": "id"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.selectedMainOfficeId,
      callback: function ($$v) {
        _vm.selectedMainOfficeId = $$v;
      },
      expression: "selectedMainOfficeId"
    }
  })], 1), !_vm.hasMultiLevel && _vm.showRegionalOffice ? _c('BaseInfoCol', {
    attrs: {
      "label": "Regionkontor: *"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "data-cy": "appForm-regional-office-select",
      "items": _vm.activeRegionalOffices,
      "rules": _vm.isRegionalOffices && _vm.activeRegionalOffices ? [_vm.validateNotEmpty] : [],
      "label": "Regionkontor",
      "item-text": "name",
      "item-value": "id"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.selectedRegionalOfficeId,
      callback: function ($$v) {
        _vm.selectedRegionalOfficeId = $$v;
      },
      expression: "selectedRegionalOfficeId"
    }
  })], 1) : _vm._e(), _vm.hasMultiLevel && _vm.showRegionalOffice ? _c('BaseInfoCol', {
    attrs: {
      "label": "Avdeling: *"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.memberOrgLocalOffices,
      "rules": _vm.isRegionalOffices && _vm.memberOrgLocalOffices ? [_vm.validateNotEmpty] : [],
      "loading": _vm.loadingLocalOffices,
      "label": "Avdeling *",
      "item-text": "name",
      "item-value": "id"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.selectedRegionalOfficeId,
      callback: function ($$v) {
        _vm.selectedRegionalOfficeId = $$v;
      },
      expression: "selectedRegionalOfficeId"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }